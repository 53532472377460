<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the molar solubility,
        <stemble-latex content="$\text{S},$" />
        of
        <chemical-latex content="Ag2(CrO4)" />
        in a
        <number-value :value="concCommonIon" unit="\text{M}" />
        solution of
        <chemical-latex content="K2(CrO4)\text{.}" />
        Note that
        <chemical-latex content="K2(CrO4)" />
        is highly soluble and the
        <stemble-latex content="$\text{K}_\text{sp}$" />
        of
        <chemical-latex content="Ag2(CrO4)" />
        is
        <stemble-latex content="$9.00\times 10^{-12}.$" />
        <!-- TODO: Not sure how to break a question up like this for the final because I don't want to
                           give them an ICE table and basically show them how to set it up. One of the biggest
                           struggles with common ion questions is what the reaction you care about actually looks like. -->
      </p>

      <calculation-input
        v-model="inputs.molarSolubility"
        prepend-text="$\text{S:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question130',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molarSolubility: null,
      },
    };
  },
  computed: {
    concCommonIon() {
      return this.taskState.getValueBySymbol('concCommonIon').content;
    },
  },
};
</script>
